import React, { useState, useEffect } from 'react';
import Pixel from './Pixel';
import { withFirebase } from '../../Firebase';
import sound from '../../../assets/complete.wav';
import { dayOfWeekInMonth } from '../../../constants/initialHabitData';

const Canvas = (props) => {
  const {
    habit,
    dispatch,
    habitName,
    makeConfetti,
    monthSelected,
    firebase,
  } = props;
  const uid = firebase.auth.currentUser.uid;
  const [row, setRow] = useState(habit[monthSelected]);
  const audio = new Audio(sound);
  const frequency = habit.frequency || [
    'sun',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
  ];
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const dayOfWeekArr = dayOfWeekInMonth(monthSelected, daysOfWeek);

  useEffect(() => {
    setRow(habit[monthSelected]);
  }, [habit, monthSelected]);

  const onHabitPixelClicked = (index) => {
    // Update color
    const newRow = JSON.parse(JSON.stringify(row));
    let currentColorIndex = newRow[index];
    if (currentColorIndex) {
      currentColorIndex = 0;
    } else {
      currentColorIndex = 1;
      // Play sound and confetti
      audio.play();
      makeConfetti();
    }

    newRow[index] = currentColorIndex;
    setRow(newRow);
    firebase.updateHabitTrackerEntry(uid, habitName, monthSelected, newRow);

    const values = {
      habitName,
      monthSelected,
      habitEntry: newRow,
    };
    dispatch({ type: 'UPDATE_HABIT', payload: values });
  };

  return (
    <div className={'canvas'}>
      {row.map((_, index) => {
        const isDisabled = !frequency.includes(dayOfWeekArr[index]);
        return (
          <Pixel
            key={index}
            background={row[index] === 0 ? 'white' : 'blue'}
            onClick={() => onHabitPixelClicked(index)}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
};

export default withFirebase(Canvas);
