import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

import LandingPage from '../Landing';
import AppDownloadPage from '../Download';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import BootcampPage from '../Bootcamp';
import MembershipSubscription from '../Membership/main';
import SideDrawer from '../SideDrawer';
import SignupConfirmation from '../Confirmation';
import Contact from '../Contact';
import MemberConfirmationPage from '../Membership/memberConfirmation';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => (
  <Router>
    <ScrollToTop />
    <div>
      <SideDrawer />

      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        exact
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route exact path={ROUTES.HOME} component={HomePage} />
      <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route exact path={ROUTES.ADMIN} component={AdminPage} />
      <Route
        exact
        path={ROUTES.MEMBERSHIP}
        component={MembershipSubscription}
      />
      <Route exact path={ROUTES.CONFIRMATION} component={SignupConfirmation} />
      <Route exact path={ROUTES.CONTACT} component={Contact} />
      <Route path={ROUTES.AFTERPAYMENT} component={MemberConfirmationPage} />
      <Route path={ROUTES.BOOTCAMP} component={BootcampPage} />
      <Route path={ROUTES.DOWNLOAD} component={AppDownloadPage} />
    </div>
  </Router>
);

export default withAuthentication(App);
