const date = new Date();
const year = date.getFullYear();

// All the days of the selected month
export const daysInMonth = (month) =>
  Array(new Date(year, month + 1, 0).getDate())
    .fill()
    .map((_, i) => i + 1);

// All the day of the week of the selected month
export const dayOfWeekInMonth = (month, daysOfWeek) => {
  const firstDay = new Date(year, month, 1).getDay();
  return Array(new Date(year, month + 1, 0).getDate())
    .fill()
    .map((_, i) => {
      if (i === 0) return daysOfWeek[firstDay];
      return daysOfWeek[((i % 7) + firstDay) % 7];
    });
};

export const yearlyTrackingData = {
  0: Array(new Date(year, 1, 0).getDate())
    .fill()
    .map(() => 0),
  1: Array(new Date(year, 2, 0).getDate())
    .fill()
    .map(() => 0),
  2: Array(new Date(year, 3, 0).getDate())
    .fill()
    .map(() => 0),
  3: Array(new Date(year, 4, 0).getDate())
    .fill()
    .map(() => 0),
  4: Array(new Date(year, 5, 0).getDate())
    .fill()
    .map(() => 0),
  5: Array(new Date(year, 6, 0).getDate())
    .fill()
    .map(() => 0),
  6: Array(new Date(year, 7, 0).getDate())
    .fill()
    .map(() => 0),
  7: Array(new Date(year, 8, 0).getDate())
    .fill()
    .map(() => 0),
  8: Array(new Date(year, 9, 0).getDate())
    .fill()
    .map(() => 0),
  9: Array(new Date(year, 10, 0).getDate())
    .fill()
    .map(() => 0),
  10: Array(new Date(year, 11, 0).getDate())
    .fill()
    .map(() => 0),
  11: Array(new Date(year, 12, 0).getDate())
    .fill()
    .map(() => 0),
};
