export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN_DETAILS = '/admin/:id';
export const MEMBERSHIP = '/membership';
export const CONFIRMATION = '/confirm';
export const CONTACT = '/contact';
export const AFTERPAYMENT = '/after-payment';
export const BOOTCAMP = '/bootcamp-c1';
export const DOWNLOAD = '/app/download';
