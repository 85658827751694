import React from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const DaysSelection = (props) => {
  const { value, handleChange, name } = props;

  return (
    <Grid item>
      <ToggleButtonGroup
        size="small"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <ToggleButton value="mon" aria-label="mon" disableRipple={true}>
          Mon
        </ToggleButton>
        <ToggleButton value="tue" aria-label="tue" disableRipple={true}>
          Tue
        </ToggleButton>
        <ToggleButton value="wed" aria-label="wed" disableRipple={true}>
          Wed
        </ToggleButton>
        <ToggleButton value="thu" aria-label="thu" disableRipple={true}>
          Thu
        </ToggleButton>
        <ToggleButton value="fri" aria-label="fri" disableRipple={true}>
          Fri
        </ToggleButton>
        <ToggleButton value="sat" aria-label="sat" disableRipple={true}>
          Sat
        </ToggleButton>
        <ToggleButton value="sun" aria-label="sun" disableRipple={true}>
          Sun
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

export default DaysSelection;
