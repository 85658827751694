import { useState } from 'react';

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e, frequency = null) => {
      if (frequency) {
        setValues({
          ...values,
          frequency: frequency,
        });
      } else if (e.target) {
        // event is from submitting the form
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        });
      } else {
        // event is from a funciton calling setValues directly
        setValues(e);
      }
    },
  ];
};

export default useForm;
