import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Pixel from './Pixel';
import {
  daysInMonth,
  dayOfWeekInMonth,
} from '../../../constants/initialHabitData';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    border: '1px solid #ced4da',
    fontSize: 22,
    // Center the month selector text
    paddingLeft: '15px',
    paddingTop: '12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const DayOfMonth = ({ monthSelected, setMonthSelected }) => {
  const date = new Date();
  const year = date.getFullYear();
  // All the days of the selected month
  const daysArr = daysInMonth(monthSelected);
  // All the day of the week of the selected month
  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const dayOfWeek = dayOfWeekInMonth(monthSelected, daysOfWeek);

  let today = 0;
  if (monthSelected === date.getMonth()) {
    today = date.getDate();
  }

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <MonthSelector
            monthSelected={monthSelected}
            setMonthSelected={setMonthSelected}
          />
        </Box>
        &nbsp;&nbsp;&nbsp;
        <Box>
          <Typography variant="h5" color="secondary">
            {year}
          </Typography>
        </Box>
      </Box>
      <div className="habitList">
        <div className="spaceAlign" />
        {daysArr.map((day, index) => {
          return (
            <Pixel
              key={index}
              day={day}
              today={today}
              background="white"
              onClick={() => {}}
            />
          );
        })}
      </div>
      <div className="habitList">
        <div className="spaceAlign" />
        {dayOfWeek.map((day, index) => {
          return (
            <Pixel
              key={index}
              day={day}
              background="white"
              onClick={() => {}}
            />
          );
        })}
      </div>
    </>
  );
};

const MonthSelector = ({ monthSelected, setMonthSelected }) => {
  const monthMap = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

  const handleChange = (e) => {
    setMonthSelected(parseInt(e.target.value));
  };

  return (
    <FormControl>
      <Select
        value={monthSelected}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {Object.keys(monthMap).map((monthItemValue) => (
          <MenuItem key={monthItemValue} value={monthItemValue}>
            {monthMap[monthItemValue]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DayOfMonth;
