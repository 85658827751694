import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormHelperText } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import useForm from './useForm';
import habitCategory from '../../constants/habitCategory';
import DaysSelection from '../../elements/DaysSelection';

const initialState = {
  habit: '',
  category: 'career',
  frequency: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 200,
  },
  fab: {
    margin: theme.spacing(3),
    color: 'white',
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
}));

export default (props) => {
  const [values, handleChange] = useForm(initialState);
  const [showError, setShowError] = useState(false);
  const classes = useStyles();
  const selections = habitCategory.map((category) => (
    <option value={category.toLowerCase()} key={category}>
      {category}
    </option>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.habit) {
      props.onSubmitNewHabit(values);
      handleChange(initialState);
    }
  };

  const handleHabitChange = (e) => {
    handleChange(e);
    if (e.target.value.length > 19) {
      setShowError(true);
    } else if (showError) {
      setShowError(false);
    }
  };

  return (
    <form>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          label="Habit name"
          className={classes.textField}
          margin="normal"
          value={values.habit}
          name="habit"
          onChange={handleHabitChange}
          inputProps={{ maxLength: 20 }}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
        <TextField
          select
          label="Category"
          name="category"
          className={classes.textField}
          margin="normal"
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu,
            },
          }}
          value={values.category}
          onChange={handleChange}
        >
          {selections}
        </TextField>
        <DaysSelection
          name="frequency"
          value={values.frequency}
          handleChange={handleChange}
        />
        <Tooltip title="Add Habit">
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            className={classes.fab}
            onClick={handleSubmit}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
      {showError && (
        <FormHelperText error={true} className={classes.leftSpacing}>
          Try to keep it less than 20 characters.
        </FormHelperText>
      )}
    </form>
  );
};
