import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { blue, cyan, orange, grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: orange[700],
      light: orange[200],
      text: grey[600],
    },
    secondary: {
      main: blue['800'],
      light: cyan[500],
    },
    // text: {
    //   primary: orange[50],
    //   secondary: grey[50],
    // },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  status: {
    blue: 'steelblue',
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  dense: {
    marginTop: 19,
  },
}));

export { theme, useStyles };
