import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import signUpImage from '../../assets/signup.svg';
import { SignInLink } from '../SignIn';

import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 50,
  },
  signUpOption: {
    marginLeft: 180,
  },
  signUpText: {
    margin: theme.spacing(1),
    display: 'inline-block',
  },
  signInImage: {
    width: 400,
    height: 'auto',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    width: 330,
  },
  button: {
    margin: theme.spacing(1),
    display: 'inline-block',
  },
}));

const SignUpPage = (props) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection={props.flexDirection ? props.flexDirection : 'row'}
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Box m={10}>
        <img src={signUpImage} alt="" className={classes.signInImage} />
      </Box>
      <div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            <Typography
              noWrap={true}
              variant="h6"
              className={classes.signUpText}
            >
              Sign up
            </Typography>
          </Box>
          <Box>
            <SignInLink />
          </Box>
        </Box>
        <SignUpForm topic={props.topic} />
      </div>
    </Box>
  );
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
    An account with this E-Mail address already exists.
    Try to login with this account instead. If you think the
    account is already used from one of the social logins, try
    to sign-in with one of them. Afterward, associate your accounts
    on your personal account page.
`;

const INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  isAdmin: false,
  error: null,
};

const SignUpFormBase = (props) => {
  const [state, setState] = useState(INITIAL_STATE);
  const classes = useStyles();

  const onSubmit = (event) => {
    const { username, email, password, isAdmin } = state;
    const roles = [];

    if (isAdmin) {
      roles.push(ROLES.ADMIN);
    }

    props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        const profile = {
          displayName: username,
        };
        props.firebase.doUpdateProfile(profile);
        props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles,
            topic: props.topic || '',
          })
          .then(() => {
            props.firebase.doSendEmailVerification();
          })
          .then(() => {
            setState(INITIAL_STATE);
            if (props.topic) {
              props.history.push(ROUTES.CONFIRMATION);
            } else {
              props.history.push(ROUTES.HOME);
            }
          })
          .catch((error) => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }

            setState({ ...state, error });
          });
      })
      .catch((error) => {
        setState({ ...state, error });
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    const signUpInfo = {
      ...state,
      [event.target.name]: event.target.value,
    };
    setState(signUpInfo);
  };

  // const onChangeCheckbox = event => {
  //     setState({ [event.target.name]: event.target.checked });
  // };

  const {
    username,
    email,
    password,
    // isAdmin,
    error,
  } = state;

  const isInvalid = password === '' || email === '' || username === '';

  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="username"
        variant="outlined"
        value={username}
        type="text"
        label="Username"
        className={classes.textField}
        margin="normal"
        onChange={onChange}
      />
      <TextField
        name="email"
        variant="outlined"
        value={email}
        type="email"
        label="Email"
        className={classes.textField}
        margin="normal"
        onChange={onChange}
      />
      <TextField
        name="password"
        variant="outlined"
        value={password}
        type="password"
        label="Password"
        className={classes.textField}
        margin="normal"
        onChange={onChange}
      />
      {/* <label>
                Admin:
                <input
                    name="isAdmin"
                    type="checkbox"
                    checked={isAdmin}
                    onChange={this.onChangeCheckbox}
                />
            </label> */}
      <Button
        disabled={isInvalid}
        className={classes.button}
        variant="contained"
        type="submit"
      >
        Sign Up
      </Button>
      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignUpLink = () => {
  const classes = useStyles();
  return (
    <Typography
      noWrap={true}
      variant="subtitle1"
      className={classes.signUpOption}
    >
      or
      <NavLink to={ROUTES.SIGN_UP} className="signUp">
        Sign Up
      </NavLink>
    </Typography>
  );
};

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
