import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'center',
    padding: '60px',
  },
}));

const SignupConfirmation = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.text} variant="h6">
        Thank you for signing up! Here's the{' '}
        <Link href="https://calendly.com/chovic/30min">Calender Link</Link>{' '}
        through which you can schedule the initial session with us.
      </Typography>
    </div>
  );
};

export default SignupConfirmation;
