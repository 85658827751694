import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { yearlyTrackingData } from '../../constants/initialHabitData';
import debounce from '../../utils/debounce';
import { membershipFee } from '../../constants/memberFee';

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  confirmationEmailRedirect:
    process.env.REACT_APP_PROD_CONFIRMATION_EMAIL_REDIRECT,
};

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};

const config = process.env.NODE_ENV === 'development' ? devConfig : prodConfig;
const date = new Date();
const month = date.getMonth();

class Firebase {
  constructor() {
    firebase.initializeApp(config);

    this.serverValue = firebase.database.ServerValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;
    this.auth = firebase.auth();
    this.db = firebase.firestore();

    this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doUpdateProfile = (profile) => this.auth.currentUser.updateProfile(profile);

  doSendEmailVerification = () => {
    const res = this.auth.currentUser.sendEmailVerification({
      url: 'https://chovic.com/home',
    });
    return res;
  };

  _sendEmail = (username, email) => {
    this.db.collection('emails').add({
      to: email,
      template: {
        name: 'WelcomeEmail',
        data: {
          username: username,
        },
      },
    });
  };

  // If auth.onAuthStateChanged is invoked more than once under 1 second, only send out one email.
  sendEmail = debounce(this._sendEmail, 1000, true);

  _userSignedInCheckpoint = (authUser, next, fallback) => {
    if (authUser) {
      const userDoc = this.user(authUser.uid).get();
      userDoc.then((doc) => {
        const data = doc.data();
        if (data) {
          // If user just verified their email address, update the emailVerified field in the database and send out the welcome email.
          const { emailVerified, username, email } = data;
          if (!emailVerified) {
            if (authUser.emailVerified) {
              this.user(authUser.uid).update({
                emailVerified: authUser.emailVerified,
              });
              this.sendEmail(username, email);
            }
          }

          // Merge firebase auth user information to the user information in the database.
          if (data && !data.roles) data.roles = [];
          authUser = {
            ...data,
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
          };
          next(authUser);
        }
      });
    } else {
      fallback();
    }
  };

  // *** Merge Auth and DB User API ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      this._userSignedInCheckpoint(authUser, next, fallback);
    });

  // *** User API ***
  user = (uid) => {
    return this.db.collection(`users`).doc(`${uid}`);
  };

  users = () => this.db.collection('users').get();

  bootcampUser = () => {
    return this.db.collection('bootcamp-users');
  };

  betaUser = () => {
    return this.db.collection('beta_user');
  };

  // *** Habit API ***
  getAllHabits = async (uid) => {
    const allHabits = {};
    const snapshot = await this.user(uid).collection('habits').get();
    snapshot.forEach((doc) => {
      allHabits[doc.id] = doc.data();
    });
    return allHabits;
  };

  // This function adds a new habit to the database and returns a promise after the action is complete.
  addHabit = (uid, habit, category, frequency) => {
    const userInfo = this.user(uid);
    return userInfo
      .collection('habits')
      .doc(habit)
      .set(
        {
          category: category,
          frequency: frequency,
          startDate: firebase.firestore.FieldValue.serverTimestamp(),
          ...yearlyTrackingData,
        },
        { merge: true }
      );
  };

  deleteHabit = (uid, habit) => {
    const userInfo = this.user(uid);
    return userInfo.collection('habits').doc(habit).delete();
  };

  updateHabitTrackerEntry = (uid, habit, monthSelected, entryArr) => {
    const userInfo = this.user(uid);
    userInfo
      .collection('habits')
      .doc(habit)
      .set(
        {
          [monthSelected]: entryArr,
        },
        { merge: true }
      );
  };

  getHabitTrackerEntry = async (uid, habit) => {
    const userInfo = this.user(uid);
    let trackingData;
    const habitEntry = await userInfo.collection('habits').doc(habit).get();
    if (habitEntry) {
      trackingData = habitEntry.data()[month];
    } else {
      console.log(`No entry for ${habit} found.`);
    }
    return trackingData;
  };

  // *** Message API ***
  message = (uid) => this.db.collection(`messages`).doc(`${uid}`);

  messages = () => this.db.collection('messages').get();

  // *** Application API ***
  application = (applicationId) =>
    this.db.collection(`applications`).doc(`${applicationId}`);

  applications = () => this.db.collection('applications');

  // *** Payment API ***
  // '/stripe_customers/{userId}/tokens/{pushId}')
  setToken = (uid, tokenId) =>
    this.db
      .collection('stripe_customers')
      .doc(`${uid}`)
      .collection('tokens')
      .add({ token: tokenId });

  // '/stripe_customers/{userId}/charges/{id}'
  setCharge = (uid) =>
    this.db
      .collection('stripe_customers')
      .doc(`${uid}`)
      .collection('charges')
      .add({
        amount: membershipFee,
      });

  // Check whether the source (card) information is written to the database, need to verify this before charging customer.
  getSourceDocs = (uid) =>
    this.db
      .collection('stripe_customers')
      .doc(`${uid}`)
      .collection('sources')
      .get();

  // Check whether a given user has paid for being a member
  checkPayment = (uid) =>
    this.db
      .collection('stripe_customers')
      .doc(`${uid}`)
      .collection('charges')
      .get();
}

export default Firebase;
