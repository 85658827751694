import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import './styles.css';
import { displayMemberFee } from '../../constants/memberFee';
import transparent_logo from '../../assets/transparent_logo.png';
import payment from '../../assets/payment.jpeg';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 150,
  },
  description: {
    marginLeft: 15,
  },
  price: {
    fontFamily: 'Rockwell',
    marginTop: 20,
  },
  image: {
    width: '80%',
  },
}));

const ProductPage = () => {
  const classes = useStyles();
  return (
    <div>
      <img src={transparent_logo} alt="Chovic" className={classes.logo} />
      <div className={classes.description}>
        <Typography variant="h5" className="productTitle">
          21-Day Habit Bootcamp
        </Typography>
        <Typography variant="h5" className={classes.price}>
          {displayMemberFee}
        </Typography>
      </div>
      <img src={payment} alt="" className={classes.image} />
    </div>
  );
};

export default ProductPage;
