import React from 'react';
import '../styles.css';
import crossed from '../../../assets/delete.png';

export default (props) => {
  const { day, today, background, onClick, disabled } = props;
  const pixelType = day ? 'day-of-month' : 'tracker';
  let className = disabled
    ? background + ' pixel ' + pixelType + ' disabled'
    : background + ' pixel ' + pixelType;
  let todayDate = '';
  if (day === today && pixelType === 'day-of-month') {
    todayDate = 'todayDate';
  }

  return (
    <div>
      {day ? (
        <div className={className}>
          <div className={todayDate}>{day}</div>
        </div>
      ) : (
        <div className={className} onClick={onClick}>
          {disabled ? (
            <img className="crossed" src={crossed} alt="crossed" />
          ) : null}
        </div>
      )}
    </div>
  );
};
