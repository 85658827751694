import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import PaymentPage from './payment';
import ProductPage from './product';

const useStyles = makeStyles((theme) => ({
  rootLeft: {
    width: 500,
    marginLeft: '30%',
    marginTop: '3%',
    [theme.breakpoints.up('md')]: {
      float: 'left',
    },
  },
  rootRight: {
    width: 400,
    marginLeft: '30%',
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      marginLeft: '60%',
      marginTop: 220,
    },
  },
}));

const MembershipSubscriptionBase = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.rootLeft}>
        <ProductPage />
      </div>
      <div className={classes.rootRight}>
        <PaymentPage />
      </div>
    </>
  );
};

const MembershipSubscription = withFirebase(MembershipSubscriptionBase);
export default compose(
  withEmailVerification,
  withAuthorization((authUser) => !!authUser)
)(MembershipSubscription);
