import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    top: '50%',
    left: '50%',
    position: 'absolute'
  },
}));

const AppDownloadPage = () => {
  const classes = useStyles();
    return (
      <div className={classes.root}>
        <a href="itms-services://?action=download-manifest&url=https://www.chovic.com/apps/manifest.plist">Download</a>
      </div>
    )
}

export default AppDownloadPage