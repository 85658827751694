import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '20%',
    paddingLeft: '10%',
  },
  heading: {
    marginBottom: 30,
  },
  text: {
    display: 'inline',
  },
  email: {
    color: theme.palette.primary.main,
    display: 'inline',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.heading}>
        We are here to help.
      </Typography>
      <Typography variant="h6" className={classes.text}>
        Have any questions? Email{' '}
      </Typography>
      <Typography variant="h6" className={classes.email}>
        support@chovic.com
      </Typography>
    </div>
  );
};
