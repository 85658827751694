import React, { useRef, useState } from 'react';
import { motion, useTransform, useElementScroll } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextField, Button } from '@material-ui/core';

import { withFirebase } from '../Firebase';
import chovicLogo from '../../assets/transparent_logo.png';
import image1 from '../../assets/1.svg';
import image2 from '../../assets/2.svg';
import image3 from '../../assets/3.svg';
import image4 from '../../assets/4.svg';
import mobile from '../../assets/mobile.png';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
  },
  leftSection: {
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(15),
  },
  introImage: {
    maxWidth: 500,
    marginTop: 20,
    marginBottom: 70,
  },
  screenshot: {
    width: '90%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  habitTrackerImage: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    width: '100%',
    height: 'auto',
  },
  logo: {
    width: 180,
  },
  titleHow: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Crimson Text',
    textAlign: 'center',
    color: '#fff3e0', // white orange
    marginBottom: 50,
  },
  title: {
    fontFamily: 'rokkitt',
    color: '#fff3e0', // white orange
    maxWidth: 500,
    padding: 20,
  },
  intro: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Crimson Text',
    color: '#fff3e0', // white orange
  },
  body: {
    marginTop: theme.spacing(3),
    maxWidth: 700,
    color: '#fafafa', // white grey
  },
  signupText: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    color: theme.palette.primary.main,
    fontFamily: 'Crimson Text',
    textAlign: 'center',
  },
  textField: {
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 10,
    },
  },
  thankyou: {
    fontFamily: 'Roboto Mono',
    color: theme.palette.primary.main,
  },
  submitButton: {
    fontFamily: 'Roboto Mono',
    [theme.breakpoints.down('xs')]: {
      width: '50vw',
      marginBottom: 50,
      marginTop: 30,
    },
  },
  bubble: {
    maxWidth: 900,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(10),
    },
  },
  footer: {
    height: '20vh',
    marginBottom: 30,
  },
}));

const LandingPage = ({ firebase }) => {
  const classes = useStyles();
  const [topicSelected, setTopicSelected] = useState('');
  const [betaUserInfo, setBetaUserInfo] = useState({
    name: '',
    email: '',
  });
  const [signedUpForBeta, setSignedUpForBeta] = useState(false);

  const ThankYou = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.footer}
      >
        <Typography className={classes.thankyou} variant="h6">
          Thank you for signing up!
        </Typography>
      </Box>
    );
  };

  const topicColorMap = {
    Emotional: '#C39BD3', // purple
    Vocational: '#85C1E9', // blue
    Physical: '#F39C12', // orange
    Relational: '#EC7063', // red
    Financial: '#45B39D', // teel
    Intellectual: '#FFDF00', // yellow
    Environmental: '#27AE60', // green
    Spiritual: '#F3E5AB', // vanilla
  };
  const colorClassMap = {
    '#C39BD3': 'purple',
    '#85C1E9': 'blue',
    '#F39C12': 'orange',
    '#EC7063': 'red',
    '#45B39D': 'teel',
    '#FFDF00': 'yellow',
    '#27AE60': 'green',
    '#F3E5AB': 'vanilla',
  };

  const ref = useRef();
  const { scrollYProgress } = useElementScroll(ref);

  const selectTopic = (event) => {
    setTopicSelected(event.target.id);
  };

  const onSubmit = (event) => {
    firebase
      .betaUser()
      .add({
        ...betaUserInfo,
        created: new Date().toDateString(),
      })
      .then(() => setSignedUpForBeta(true))
      .catch((err) => console.log(err));
    event.preventDefault();
  };

  const onChange = (event) => {
    const betaUser = {
      ...betaUserInfo,
      [event.target.name]: event.target.value,
    };
    setBetaUserInfo(betaUser);
  };

  const bubbles = Object.keys(topicColorMap).map((topic) => {
    const selected = topic === topicSelected;
    const color = selected ? colorClassMap[topicColorMap[topic]] : '';

    return (
      <motion.div
        className={`bubble ${color}`}
        key={topic}
        id={topic}
        whileHover={{ scale: 1.2, backgroundColor: topicColorMap[topic] }}
        whileTap={{
          scale: 0.8,
        }}
        onClick={selectTopic}
      >
        {topic}
      </motion.div>
    );
  });

  const formInputIsValid =
    betaUserInfo.name !== '' && betaUserInfo.email !== '';

  return (
    <>
      <div className={classes.leftSection} ref={ref}>
        <IntroSection scrollYProgress={scrollYProgress} />
      </div>
      {signedUpForBeta ? (
        <ThankYou />
      ) : (
        <div className={classes.footer}>
          <Typography className={classes.signupText} variant="h5">
            Sign up for Beta and get notified when our app is released!
          </Typography>
          <form onSubmit={onSubmit}>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              <TextField
                name="name"
                variant="outlined"
                type="text"
                label="Name"
                value={betaUserInfo.name}
                onChange={onChange}
                className={classes.textField}
              />
              <TextField
                name="email"
                variant="outlined"
                type="email"
                label="Email"
                value={betaUserInfo.email}
                onChange={onChange}
                className={classes.textField}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={!formInputIsValid}
                className={classes.submitButton}
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        </div>
      )}
      {/* <div className={classes.bubble}>{bubbles}</div> */}
    </>
  );
};

const IntroSection = ({ scrollYProgress }) => {
  const classes = useStyles();
  const opacity = useTransform(scrollYProgress, [0, 0.1, 1], [1, 0.1, 0]);

  return (
    <Box display="flex" flexDirection="column">
      <Box m="auto">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
          }}
        >
          <img className={classes.logo} src={chovicLogo} alt="Chovic" />
        </motion.div>
      </Box>
      {/* First section */}
      <Box m="auto" p={2}>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: opacity.current,
          }}
          transition={{
            duration: 2,
          }}
        >
          <Typography className={classes.intro} variant="h4">
            <motion.div
              style={{
                opacity,
              }}
            >
              Smart wellbeing coach powered by habit building AI and your social
              network
            </motion.div>
          </Typography>
        </motion.div>
      </Box>

      <motion.div
        initial={{
          opacity: 0,
          y: 50,
        }}
        animate={{
          opacity: 1,
          y: 1,
        }}
        transition={{
          duration: 2,
        }}
      >
        <img className={classes.screenshot} src={mobile} alt="" />
      </motion.div>

      {/* second section */}
      <Typography className={classes.titleHow} variant="h4">
        How it works:
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-around" p={2}>
        <Box>
          <Typography className={classes.title} variant="h5">
            1. We learn more about your baseline and goals by asking you a few
            questions.
          </Typography>
          <img src={image1} alt="" className={classes.introImage} />
        </Box>

        <Box>
          <Typography className={classes.title} variant="h5">
            2. Based on your answers, we generate a personalized action plan to
            get you from good to great.
          </Typography>
          <img src={image2} alt="" className={classes.introImage} />
        </Box>

        <Box>
          <Typography className={classes.title} variant="h5">
            3. We keep track of your progress and help you stay on track with
            our smart coaching system.
          </Typography>
          <img src={image3} alt="" className={classes.introImage} />
        </Box>

        <Box>
          <Typography className={classes.title} variant="h5">
            4. We introduce you to a community that is passionate about personal
            growth and wellbeing.
          </Typography>
          <img src={image4} alt="" className={classes.introImage} />
        </Box>
      </Box>
    </Box>
  );
};

export default withFirebase(LandingPage);
